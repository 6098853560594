<!-- 发布需求 -->
<template>
  <div class="container">
    <el-card>
      <h3 v-show="ruleForm.auditReject != null && $route.query.type == 2">拒绝理由：{{ruleForm.auditReject}}</h3>
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        label-width="100px"
        class="ruleForm"
      >
        <el-form-item
          prop="bankAccount"
          label-width="135"
          class="el-form-item-line"
        >
          <div class="line">需求填写</div>
        </el-form-item>
        <el-form-item
          label="工程名称"
          prop="projectName"
          label-width="135"
          style="width: 80%;"
        >
          <el-input v-model="ruleForm.projectName" size="small"></el-input>
        </el-form-item>
        <el-form-item label="工程简述" prop="projectDec" style="width: 80%;" label-width="135">
          <el-input v-model="ruleForm.projectDec" size="small"></el-input>
        </el-form-item>
        <el-form-item label="工程类型" prop="projectType" style="width: 80%;" label-width="135">
          <el-select
            size="small"
            v-model="ruleForm.projectType"
            placeholder="请选择"
            @change="projectTypeChange"
          >
            <el-option
              v-for="item in projectTypeList"
              :key="item.dictName"
              :label="item.dictDescribe"
              :value="item.dictName"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系电话" prop="projectContactName" style="width: 80%;" label-width="135">
          <el-input v-model="ruleForm.projectContactName" size="small"></el-input>
        </el-form-item>
        <el-form-item label="施工备注" prop="projectRemark" style="width: 80%;" label-width="135">
          <el-input v-model="ruleForm.projectRemark" size="small"></el-input>
        </el-form-item>
         <el-form-item
          label="施工地点"
          prop="model"
          label-width="135"
          style="width: 100%"
        >
          <div class="address_box">
            <el-select
              v-model="ruleForm.projectProvince"
              placeholder="请选择省"
              @change="provinceChange"
              size="small"
            >
              <el-option
                v-for="(item, index) in provinceList"
                :key="index"
                :label="item.cityName"
                :value="item.cityId"
              ></el-option>
            </el-select>
            <el-select
              v-model="ruleForm.projectCity"
              placeholder="请选择市"
              @change="cityChange"
              size="small"
            >
              <el-option
                v-for="(item, index) in cityList"
                :key="index"
                :label="item.cityName"
                :value="item.cityId"
              ></el-option>
            </el-select>
            <el-select
              v-model="ruleForm.projectCountry"
              placeholder="请选择区"
              @change="areaChange"
              size="small"
            >
              <el-option
                v-for="(item, index) in areaList"
                :key="index"
                :label="item.cityName"
                :value="item.cityId"
              ></el-option>
            </el-select>
          </div>
          <el-input
            type="textarea"
            :rows="2"
            v-model="ruleForm.projectAddress"
            placeholder="详细地址"
            style="width: 50%; margin-top: 10px; margin-left: 135px"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="projectContact" label-width="135">
         <el-input
            v-model="ruleForm.projectContact"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item label="支付方式" prop="payTypeCode" label-width="135" style="width: 50%;margin-right: auto;">
          <el-select
            size="small"
            v-model="ruleForm.payTypeCode"
            placeholder="请选择"
          >
            <el-option
              v-for="item in payTypeCodeList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="需求图片" prop="projectImg" label-width="135" style="width: 100%;">
         <el-upload
            class="avatar-uploader"
            :action="action"
            :show-file-list="false"
            :on-success="
              (res, file) => {
                handleVehicleImg(res, file, 3)
              }
            "
          >
            <img v-if="ruleForm.projectImg" :src="ruleForm.projectImg" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item
          prop="bankAccount"
          label-width="135"
          class="el-form-item-line"
        >
        <div class="line_box">
          <div class="line">
            设备需求
          </div>
          <div>
            <el-button class="submit" @click="addDriver">添加需求</el-button>
          </div>
        </div>
        </el-form-item>
         <el-form-item  style="width: 100%;" label-width="135">
          <div v-for="(item,index) in ruleForm.details" :key="index" class="drive">
            <div class="diver_box">
              <div class="title">
                机械名称
              </div>
              <el-input v-model="item.vehicleName" size="small"></el-input>
            </div>
            <div class="diver_box">
              <div class="title">
                机械型号
              </div>
              <el-input v-model="item.vehicleModel" size="small"></el-input>
            </div>
            <div class="diver_box">
              <div class="title">
                机械品牌
              </div>
              <el-select
                size="small"
                v-model="item.vehicleBrandId"
                placeholder="请选择"
                @change="changeBrandId($event,index)"
              >
                <el-option
                  v-for="v in brandList"
                  :key="v.id"
                  :label="v.brandName"
                  :value="v.id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="diver_box">
              <div class="title">
                机械类型
              </div>
              <el-cascader
                :options="carStatusList"
                @change="carStatusChange($event,index)"
                v-model="item.vehicleType"
                :props="optionProps"
                size="small"
                style="width: 50%"
                ref="vehicleTypeName"
                placeholder="请选择设备类型"
              >
              </el-cascader>
            </div>
            <div class="diver_box" style="width: 100%;">
              <div class="title">
                租赁方式
              </div>
              <el-select
                size="small"
                v-model="item.leaseTypeCode"
                placeholder="请选择"
                @change="changeLeaseType($event,index)"
                style="width: 20%;"
              >
                <el-option
                  v-for="v in leaseTypeList"
                  :key="v.id"
                  :label="v.name"
                  :value="v.id"
                >
                </el-option>
              </el-select>
              <el-input v-if="item.leaseTypeCode != 3"  v-model="item.leaseWork" size="small" style="width: 20%;margin: 0 20px;">
              </el-input>
              <el-select
                size="small"
                v-model="item.leaseUnit"
                placeholder="请选择"
                v-if="item.leaseTypeCode == 3"
                style="width: 20%;margin-left: 20px;"
                @change="changeLeaseUnit($event,index)"

              >
                <el-option
                  v-for="v in firmNumList"
                  :key="v.dictId"
                  :label="v.dictDescribe"
                  :value="v.dictId"
                >
                </el-option>
              </el-select>
              <el-input v-else v-model="item.leaseUnit" disabled size="small" style="width: 20%"></el-input>
            </div>
       
            <div class="diver_box">
              <div class="title">
                租赁开始时间
              </div>
              <el-date-picker
                size="small"
                value-format=yyyy/MM/dd
                v-model="item.leaseTimeStart"
                type="date"
                placeholder="选择日期"
              ></el-date-picker>
            </div>
            <div class="diver_box">
              <div class="title">
                租赁结束时间
              </div>
              <el-date-picker
                size="small"
                value-format=yyyy/MM/dd
                v-model="item.leaseTimeEnd"
                type="date"
                placeholder="选择日期"
              ></el-date-picker>
            </div>
            <div class="diver_box">
              <div class="title">
                保险方式
              </div>
              <el-select
                size="small"
                v-model="item.insure"
                placeholder="请选择保险方式"
              >
                <el-option
                  v-for="item in insureList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="diver_box">
              <div class="title">
                进场时间
              </div>
              <el-date-picker
                size="small"
                value-format=yyyy/MM/dd
                v-model="item.workTimeStart"
                type="date"
                placeholder="选择日期"
              ></el-date-picker>
            </div>
            <div class="diver_box">
              <div class="title">
                租赁数量
              </div>
              <el-input type="number"
                v-model="item.vechileNumber"
                size="small">
              </el-input>
            </div>
            <div class="diver_box">
              <div class="title">
                租赁费用
              </div>
              <el-input
                v-model="item.vehicleOffer"
                size="small"
                type="number">
              </el-input>
            </div>
            <el-button size="small" v-if="index != 0" @click="deleteDriver(index)" class="submit" style="margin-left: auto;margin-bottom: 15px;">删除</el-button>
          </div>
        </el-form-item>
      </el-form>
      <div class="btn">
        <el-button @click="backCar">返回</el-button>
        <el-button @click="btn" class="submit" :loading="loading"
          >创建/暂存</el-button
        >
            <el-button @click="submit" class="submit" :loading="loading"
          >提交审核</el-button
        >
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  vehicleTypeTree,
  getSelect,
  getCityTree,
  getCitySelect,
  getBrand,
  getDriverIdCardsByIdCard,
  getRequireOrderById,
  addRequireOrder,
  updateRequireOrder,
} from "@/api/user";
import { upload } from "@/api/upload";
import { json } from "body-parser";
export default {
  data() {
    return {
      loading: false,
      ruleForm: {
        projectName: "",
        projectDec: "",
        projectType: "",
        projectTypeCode: "",
        projectContactName: "",
        projectRemark: "",
        projectProvince: "",
        projectProvinceCode: "",
        projectCity: "",
        projectCityCode: "",
        projectCountry: "",
        projectCountryCode: "",
        projectAddress: "",
        projectContact: "",
        payTypeCode: "",
        projectImg: "",
        details: [
          {
            vehicleName: "",
            vehicleModel: "",
            vehicleBrand: "",
            firstLevelType: "",
            firstLevelTypeId: "",
            secondLevelType: "",
            secondLevelTypeId: "",
            vehicleBrandId: null,
            leaseType: "",
            leaseWork: null,
            leaseUnit: "",
            leaseTypeCode: "",
            insure: "",
            leaseTimeStart: "",
            leaseTimeEnd: "",
            workTimeStart: "",
            vechileNumber: null,
            vehicleOffer: null,
          },
        ],
      },
      optionProps: {
        value: "vehicleTypeId",
        label: "vehicleTypeName",
      },
      carStatusList: [],
      provinceList: [],
      cityList: [],
      areaList: [],
      brandList: [],
      leaseTypeList: [
        { id: "0", name: "小时" },
        { id: "1", name: "日" },
        { id: "2", name: "月" },
        { id: "3", name: "工作量" },
      ],
      payTypeCodeList: [
        { value: "0", name: "完工付" },
        { value: "1", name: "现结" },
        { value: "2", name: "日结" },
        { value: "3", name: "月结" },
        { value: "4", name: "季付" },
      ],
      insureList: [
        { value: 0, name: "有" },
        { value: 1, name: "无" },
      ],
      action: upload,
      restaurants: [],
      firmNumList: [],
      projectTypeList: [],
      value1: [],
    };
  },
  created() {
    getSelect("leaseUnit").then((res) => {
      if (res.code == 200) {
        this.firmNumList = res.data;
      }
    });
    getSelect("projectType").then((res) => {
      if (res.code == 200) {
        this.projectTypeList = res.data;
      }
    });
    if (this.$route.query.type == 2) {
      getRequireOrderById(this.$route.query.id).then((res) => {
        this.ruleForm = res.data;
      });
    }
    if (this.$route.query.type == 3) {
      this.ruleForm.details.shift();
      this.ruleForm.details.push(JSON.parse(this.$route.query.ruleForm));
    }
  },
  mounted() {
    this.getCityTree();
    this.vehicleTypeTree();
    getBrand().then((res) => {
      this.brandList = res.data;
    });
    getDriverIdCardsByIdCard().then((res) => {
      this.restaurants = res.data;
    });
  },
  methods: {
    changeBrandId(e, index) {
      var objVal = {};
      this.brandList.forEach((val) => {
        if (val.id == e) {
          objVal = val;
        }
      });
      this.ruleForm.details[index].vehicleBrandId = objVal.id;
      this.ruleForm.details[index].vehicleBrand = objVal.brandName;
    },
    changeLeaseType(e, index) {
      let newObj = {};
      newObj = this.leaseTypeList.find((item) => {
        return item.id === e;
      });
      this.ruleForm.details[index].leaseType = newObj.name;
      this.ruleForm.details[index].leaseTypeCode = newObj.id;
      if (e != 3) {
        this.ruleForm.details[index].leaseUnit = newObj.name;
      } else {
        this.ruleForm.details[index].leaseUnit = "";
        this.ruleForm.details[index].leaseWork = 1;
      }
    },
    changeLeaseUnit(e, index) {
      let newObj = {};
      newObj = this.firmNumList.find((item) => {
        return item.dictId === e;
      });
      this.ruleForm.details[index].leaseUnit = newObj.dictName;
      this.ruleForm.details[index].leaseUnitCode = newObj.dictId;
    },
    projectTypeChange(e) {
      let dictObj = {};
      dictObj = this.projectTypeList.find((item) => {
        return item.dictName === e;
      });
      this.ruleForm.projectType = dictObj.dictDescribe;
      this.ruleForm.projectTypeCode = dictObj.dictName;
    },
    vehicleTypeTree() {
      vehicleTypeTree().then((res) => {
        this.carStatusList = res.data;
      });
    },
    carStatusChange(e, index) {
      var name1 = "";
      this.carStatusList.forEach((item) => {
        if (item.vehicleTypeId == e[0]) {
          name1 = item.vehicleTypeName;
          if (e.length == 2 && item.children != null) {
            item.children.forEach((v) => {
              if (v.vehicleTypeId == e[1]) {
                name1 = item.vehicleTypeName + "/" + v.vehicleTypeName;
              }
            });
          }
        }
      });
      this.ruleForm.details[index].firstLevelType = name1.split("/")[0];
      this.ruleForm.details[index].secondLevelType = name1.split("/")[1];
      this.ruleForm.details[index].firstLevelTypeId = e[0];
      this.ruleForm.details[index].secondLevelTypeId = e[1];
    },
    getCityTree() {
      getCityTree().then((res) => {
        this.provinceList = res.data;
      });
    },
    provinceChange(e) {
      let provinceObj = {};
      provinceObj = this.provinceList.find((item) => {
        return item.cityId === e;
      });
      this.ruleForm.projectProvince = provinceObj.cityName;
      this.ruleForm.projectProvinceCode = provinceObj.cityId;
      getCitySelect({ cityPid: e }).then((res) => {
        this.cityList = res.data;
      });
    },
    cityChange(e) {
      let cityObj = {};
      cityObj = this.cityList.find((item) => {
        return item.cityId === e;
      });
      this.ruleForm.projectCity = cityObj.cityName;
      this.ruleForm.projectCityCode = cityObj.cityId;
      getCitySelect({ cityPid: e }).then((res) => {
        this.areaList = res.data;
      });
    },
    areaChange(e) {
      let countyObj = {};
      countyObj = this.areaList.find((item) => {
        return item.cityId === e;
      });
      this.ruleForm.projectCountry = countyObj.cityName;
      this.ruleForm.projectCountryCode = countyObj.cityId;
    },
    backCar() {
      this.$router.go(-1);
    },
    addDriver() {
      let isNull = false;
      let params = {
        vehicleName: "",
        vehicleModel: "",
        vehicleBrand: "",
        firstLevelType: "",
        firstLevelTypeId: "",
        secondLevelType: "",
        secondLevelTypeId: "",
        vehicleBrandId: null,
        leaseType: "",
        leaseWork: null,
        leaseUnit: "",
        leaseTypeCode: "",
        insure: "",
        leaseTimeStart: "",
        leaseTimeEnd: "",
        workTimeStart: "",
        vechileNumber: null,
        vehicleOffer: null,
      };
      let key = Object.keys(params);
      let list = ["secondLevelType", "secondLevelTypeId"];
      this.ruleForm.details.map((item) => {
        key.map((v) => {
          if (!list.includes(v) && !item[v] ) isNull = true;
          if (v === 'insure'&& item[v] == 0) isNull = false;
        });
      });
      if (isNull === true) {
        this.$message.warning("请将数据填写完整");
        return;
      }
      this.ruleForm.details.push({
        vehicleName: "",
        vehicleModel: "",
        vehicleBrand: "",
        firstLevelTypeId: "",
        secondLevelTypeId: "",
        vehicleBrandId: null,
        leaseType: "",
        leaseTypeCode: "",
        insure: "",
        leaseTimeStart: "",
        leaseTimeEnd: "",
        workTimeStart: "",
        vechileNumber: null,
        vehicleOffer: null,
        delete: true,
      });
    },
    deleteDriver(index) {
      this.ruleForm.details.splice(index, 1);
    },
    handleVehicleImg(res) {
      this.ruleForm.projectImg = res.data.url;
    },
    btn() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          addRequireOrder(this.ruleForm).then((res) => {
            this.$message({
              message: "提交成功",
              type: "success",
            });
            this.loading = false;
            this.$router.go(-1);
          });
        } else {
          return false;
        }
      });
    },
    submit() {
      this.loading = true;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          updateRequireOrder(this.ruleForm).then((res) => {
            this.$message({
              message: "提交成功",
              type: "success",
            });
            this.loading = false;
            this.$router.go(-1);
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.ruleForm {
  display: flex;
  flex-wrap: wrap;
  .el-input {
    width: 50%;
  }
  .el-form-item {
    width: 50%;
    margin-bottom: 10px;
  }
  ::v-deep .el-form-item__label {
    width: 135px;
  }
  .el-textarea {
    width: 50%;
  }
  .el-select {
    width: 50%;
  }
  ::v-deep .el-form-item__error {
    left: 30%;
  }
  .address_box {
    display: flex;
    .el-select {
      margin-right: 20px;
      width: 27%;
    }
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .el-form-item-line {
    width: 100%;
    border-bottom: 1px solid #ddd;
  }
  .line_box {
    display: flex;
    justify-content: space-between;
  }
  .line {
    border-bottom: 4px solid #fcb110;
    width: 80px;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 146px;
    height: 146px;
    line-height: 146px;
    text-align: center;
    border: 1px dashed #ccc;
  }
  .avatar {
    width: 146px;
    height: 146px;
    display: block;
  }
}

.drive {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  font-size: 14px;
  color: #606266;
  font-weight: 700;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
  .diver_box {
    width: 50%;
    display: flex;
    margin-bottom: 10px;
    .title {
      width: 100px;
      margin-right: 20px;
      text-align: right;
    }
  }
}
.btn {
  display: flex;
  justify-content: right;
}
</style>
